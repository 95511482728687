import { ModalView } from '@partstech/ui';
import { useCallback } from 'react';
import { JobsList } from 'features/jobsList';
import { useStartSearch } from 'features/searchForm';
import type { ModalViewProps } from '@partstech/ui';
import type { Vehicle } from 'entities/vehicle';
import type { Job } from 'models';

type Props = ModalViewProps & {
  onSelectVehicleClick: () => void;
  vehicle: Vehicle | null;
};

const emptyJobs: Job[] = [];

export const RelatedJobsModal = ({ vehicle, onClose, onSelectVehicleClick }: Props) => {
  const startSearch = useStartSearch();

  const handleChange = useCallback(
    (job: Job) => {
      startSearch(job, vehicle);
      onClose?.();
    },
    [onClose, startSearch, vehicle]
  );

  const handleSelectVehicleClick = useCallback(() => {
    onSelectVehicleClick();
    onClose?.();
  }, [onClose, onSelectVehicleClick]);

  return (
    <ModalView onClose={onClose} title="Related Jobs">
      <JobsList
        options={vehicle?.jobs ?? emptyJobs}
        onChange={handleChange}
        hasVehicle={Boolean(vehicle)}
        onSelectVehicleClick={handleSelectVehicleClick}
      />
    </ModalView>
  );
};
