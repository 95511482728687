import { keys } from '@partstech/ui/utils';
import { SessionStorageKeys } from 'constant';
import { api, gExportApi, gUploadApi, shopApi } from 'shared/api';
import type { RootDispatch } from 'store';

const excludeSessionStorageKeys: string[] = [SessionStorageKeys.LOCATION_HISTORY];

export const resetStore = (dispatch: RootDispatch) => {
  dispatch(api.util.resetApiState());
  dispatch(shopApi.util.resetApiState());
  dispatch(gUploadApi.util.resetApiState());
  dispatch(gExportApi.util.resetApiState());

  keys(sessionStorage).forEach((key) => {
    if (!excludeSessionStorageKeys.includes(key)) {
      sessionStorage.removeItem(key);
    }
  });

  localStorage.clear();
};
