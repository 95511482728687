import { css } from '@emotion/react';
import { Box, Button, Card, Divider, Dropdown, Icon, Menu, MenuItem, Typography, useMedia } from '@partstech/ui';
import { useCallback, useEffect, useState } from 'react';
import type { ButtonProps } from '@partstech/ui';
import type { PriceLine, PriceLineType } from 'types/pricePackage';

type Props = {
  recentLines: PriceLine[];
  isDisabled?: boolean;
  onPriceLineCreate: (priceLine: PriceLine) => void;
  label?: string;
  buttonProps?: ButtonProps;
  onPriceLineOpen: (priceLineType: Exclude<PriceLineType, 'FET'>) => void;
};

const styles = {
  recentLinesMenu: css`
    & > div {
      box-shadow: none;
    }
  `,
  menuContainer: css`
    .ui-card-inner {
      overflow: hidden;
    }
  `,
};

export const PriceLinesMenu = ({
  recentLines,
  isDisabled = false,
  onPriceLineCreate,
  buttonProps = { variant: 'secondary', leadingIcon: 'add', disabled: isDisabled },
  label,
  onPriceLineOpen,
}: Props) => {
  const { isMobile } = useMedia();
  const [paginatedRecentLines, setPaginatedRecentLines] = useState<PriceLine[]>([]);

  const handleCreatePriceLine = useCallback(
    (priceLine: PriceLine, onCreate?: () => void) => () => {
      onPriceLineCreate(priceLine);
      if (onCreate) {
        onCreate();
      }
    },
    [onPriceLineCreate]
  );

  const handlePriceLineOpen = useCallback(
    (type: Exclude<PriceLineType, 'FET'>, onOpen?: () => void) => () => {
      onPriceLineOpen(type);

      if (onOpen) {
        onOpen();
      }
    },
    [onPriceLineOpen]
  );

  useEffect(() => {
    setPaginatedRecentLines(recentLines.slice(0, 5));
  }, [recentLines]);

  const loadMorePackageLines = useCallback(() => {
    setPaginatedRecentLines((paginated) => recentLines.slice(0, paginated.length + 5));
  }, [recentLines]);

  if (recentLines.length === 0) {
    return (
      <Dropdown
        disableAnimation
        renderButton={({ toggleHandler }) => (
          <Button data-testid="addNewPackageLineButton" {...buttonProps} onClick={toggleHandler}>
            {label}
          </Button>
        )}
      >
        {({ closeHandler }) => (
          <Menu
            onSelect={() => {
              closeHandler();
            }}
          >
            <MenuItem onClick={handlePriceLineOpen('LABOR')}>Labor</MenuItem>
            <MenuItem onClick={handlePriceLineOpen('ADDON')}>Add-on</MenuItem>
            <MenuItem onClick={handlePriceLineOpen('DISPOSAL')}>Disposal fee</MenuItem>
            <MenuItem onClick={handlePriceLineOpen('FEE')}>Fee</MenuItem>
            <MenuItem onClick={handlePriceLineOpen('DISCOUNT')}>Discount</MenuItem>
          </Menu>
        )}
      </Dropdown>
    );
  }

  return (
    <Dropdown
      disableAnimation
      renderButton={({ toggleHandler }) => (
        <Button
          variant="secondary"
          leadingIcon="add"
          onClick={toggleHandler}
          data-testid="openPriceLineDropdown"
          disabled={isDisabled}
        >
          {label}
        </Button>
      )}
    >
      {({ closeHandler: parentCloseHandler }) => (
        <Card css={styles.menuContainer} onMouseLeave={parentCloseHandler}>
          <Dropdown
            renderButton={({ toggleHandler }) => (
              <MenuItem
                prefix={<Icon name={isMobile ? 'expand_more' : 'chevron_right'} />}
                onClick={toggleHandler}
                data-testid="addPriceLineButton"
              >
                Add new line item
              </MenuItem>
            )}
            preferPlacement="right-start"
          >
            {({ openHandler, closeHandler }) => (
              <div onMouseEnter={openHandler} role="presentation">
                <Menu
                  onSelect={() => {
                    closeHandler();
                    parentCloseHandler();
                  }}
                >
                  <MenuItem data-testid="LABOR-openButton" onClick={handlePriceLineOpen('LABOR')}>
                    Labor
                  </MenuItem>
                  <MenuItem data-testid="ADDON-openButton" onClick={handlePriceLineOpen('ADDON')}>
                    Add-on
                  </MenuItem>
                  <MenuItem data-testid="DISPOSAL-openButton" onClick={handlePriceLineOpen('DISPOSAL')}>
                    Disposal fee
                  </MenuItem>
                  <MenuItem data-testid="FEE-openButton" onClick={handlePriceLineOpen('FEE')}>
                    Fee
                  </MenuItem>
                  <MenuItem data-testid="DISCOUNT-openButton" onClick={handlePriceLineOpen('DISCOUNT')}>
                    Discount
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Dropdown>
          <Divider />
          <Box css={styles.recentLinesMenu}>
            <Menu
              builtIn
              data-testid="recentLinesMenu"
              maxHeight={300}
              onSelect={() => {
                parentCloseHandler();
              }}
              onYReachEnd={loadMorePackageLines}
            >
              <Box data-testid="recentLinesList">
                {paginatedRecentLines.map((line, index) => {
                  const isPercentage = line.type !== 'LABOR' && line.isPercentage;
                  return (
                    <MenuItem
                      data-testid={`recentLine-${index}`}
                      key={`${line.name}-${line.type}-${line.price}-${index}`}
                      onClick={handleCreatePriceLine(line)}
                    >
                      {line.name}
                      <Typography variant="bodyText2" color="subtleText">
                        {line.type}, {!isPercentage ? '$' : ''}
                        {isPercentage ? line.price : line.price.toFixed(2)}
                        {isPercentage ? '%' : ''}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Box>
            </Menu>
          </Box>
        </Card>
      )}
    </Dropdown>
  );
};
